import type { TeamEvent, TimelineEventClasses, Vertex } from '../../../../types'
import * as d3 from 'd3'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import { type BaseType } from 'd3'

export default class TeamEventRenderer extends AbstractSvgRenderer {
  private holderG: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined> | undefined

  public config = {
    hasHtml: false,
    hasSubtitle: false,
    hasClassTitle: false,
    filterCircleRadius: 1000,
    noArrowsOnSafari: false,
  }

  public getClassTitle(d: TeamEvent) {
    return d.title
  }

  public getSubTitle(d: TeamEvent) {
    return ''
  }

  public getHtmlWidthFactor(d: TeamEvent) {
    return 1
  }

  public getHTML(d: TeamEvent) {
    return ''
  }

  getClassName(): TimelineEventClasses {
    return 'team-event'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return ''
  }

  protected createPhotoSvg(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
  ) {
    this.holderG = g
      .selectAll<SVGGElement | BaseType, Vertex>('svg.team-event')
      .append('g')
      .attr('class', 'team-event-photo')
      .attr('clip-path', 'url(#teamEventClip)')

    this.holderG
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.SVGWIDTH)
      .attr('height', this.SVGWIDTH)
      .attr('href', (vertex: Vertex) => {
        const p = vertex.timelineEvent as TeamEvent
        return p.photos[0]?.filename + '/m/500x500/smart/filters:grayscale()' ?? ''
      })
      .attr('clip-path', 'url(#teamEventClip)')
  }

  protected createCropMask(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    this.circleRadii['event']
    g.append('defs')
      .append('clipPath')
      .attr('id', 'teamEventClip')
      .append('circle')
      .attr('cx', this.SVGWIDTH / 2)
      .attr('cy', this.SVGWIDTH / 2)
      .attr('r', this.SVGWIDTH / 2)
  }

  public render(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>, isZooming: boolean) {
    if (isZooming) return
    const vertices = this.mycelModel.getVerticesByClass('team-event')

    this.createCropMask(g)
    this.createGroupSvg(g, vertices)
    this.createPhotoSvg(g)
  }
}
