/**
 * Responsible for rendering the welcome message.
 *
 */
import * as d3 from 'd3'
import { MycelModel } from '@/lib/MycelModel'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import i18n from '@/i18n'

/**
 * Animation that runs when the app is started.
 */
export class WelcomeAnimation {
  private readonly svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>
  private readonly width: number
  private readonly height: number
  private readonly mycelModel: MycelModel
  private readonly deviceHandler: DeviceAbstract

  private overlay: d3.Selection<SVGRectElement, unknown, HTMLElement, undefined> | undefined
  private gCircle: d3.Selection<SVGGElement, unknown, HTMLElement, undefined> | undefined
  private childSvg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined> | undefined
  private html: d3.Selection<SVGForeignObjectElement, unknown, HTMLElement, undefined> | undefined
  isVisible: boolean

  constructor(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    mycelModel: MycelModel,
    deviceHandler: DeviceAbstract
  ) {
    this.svg = svg
    this.width = parseInt(svg.attr('width'))
    this.height = parseInt(svg.attr('height'))

    this.mycelModel = mycelModel
    this.deviceHandler = deviceHandler
    this.isVisible = false
  }

  public run() {
    this.animateCircle()
  }

  public getIsVisible() {
    return this.isVisible
  }

  protected animateCircle() {
    const relativePadding = 0.1

    const transform = d3.zoomTransform(this.svg!.node() as Element);
    const circleRadius = Math.min((Math.min(this.width / transform.k, this.height / transform.k) * (1 - relativePadding)) / 2, 200)

    this.isVisible = true

    const g = this.svg.select('.graph-container')

    this.overlay = g
      .append('rect')
      .raise()
      .attr('id', 'welcome-filter-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'white')
      .style('opacity', 1)

    this.overlay.transition().delay(2000).duration(2000).style('opacity', 0.8)

    const centerScreen = [this.width / 2, this.height / 2] as [number, number];
    const centerTransformed = transform.invert(centerScreen);

    this.gCircle = g
      .append('g')
      .attr('transform-origin', `${centerTransformed[0]}px ${centerTransformed[1]}px`)
      .attr('transform', `scale(1)`)

    this.gCircle
      .append('circle')
      .attr('cx', centerTransformed[0])
      .attr('cy', centerTransformed[1])
      .attr('r', 0)
      .transition()
      .duration(1000)
      .attr('r', circleRadius)

    this.childSvg = this.gCircle
      .append('svg')
      .attr('height', circleRadius * 2)
      .attr('width', circleRadius * 2)
      .attr('viewBox', `0 0 400 400`)
      .attr('opacity', 0)
      .attr('x', centerTransformed[0] - circleRadius)
      .attr('y', centerTransformed[1] - circleRadius)

    this.html = this.childSvg
      .append('foreignObject')
      .attr('class', 'welcome')
      .attr('width', '100%')
      .attr('height', '100%')

    // this.childSvg.attr('opacity', 0).attr('x', 0).attr('y', 0)

    this.html.append('xhtml:div').html(`<div class='hero'>
                           ${i18n.global.t('welcomeBubble.welcomeHead')}
                          </div>
                          <p class='my-2'>
                           ${i18n.global.t('welcomeBubble.bodyText')}
                        </p>
                        <button class='font-bold bg-white p-2 my-2 rounded-full'> ${i18n.global.t(
                          'welcomeBubble.continueButton'
                        )}</button>
            `)

    this.gCircle.on('click', () => {
      // vector to the welcomeVertex
      this.collapseCircle()
    })

    // Transition
    this.childSvg.transition().delay(1000).duration(1000).attr('opacity', 1)
  }

  public collapseCircle() {
    const target = this.mycelModel.getWelcomeVertex()
    const scale = 0.02

    if (!this.gCircle || !this.overlay || !this.html) return

    if (!target) {
      console.warn('Welcome vertex not set')
      this.gCircle.remove()
    } else {
      const deltaX = -this.width / 2 + target.x
      const deltaY = -this.height / 2 + target.y
      this.gCircle
        .transition()
        .duration(1000)
        .attr('transform', `translate(${deltaX}, ${deltaY} ) scale(${scale})`)
        .remove()
    }
    this.overlay.transition().duration(1000).style('opacity', 0).remove()
    this.html
      .transition()
      .duration(1000)
      .attr('opacity', '0')
      .remove()
      .on('end', () => {
        this.svg.node()!.dispatchEvent(new CustomEvent('welcomeBubbleClosed'))
      })
    this.isVisible = false
  }
}
