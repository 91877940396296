import type { BlogArticle, TimelineEventClasses, Vertex } from '../../../../types'
import type { Selection } from 'd3'
import i18n from '@/i18n'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'

export class BlogRenderer extends AbstractSvgRenderer {
  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 80,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true
  }

  getClassName(): TimelineEventClasses {
    return 'blogarticle'
  }

  getCssClassName(d: Vertex) {
    return 'blogarticle'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.blog'
  }

  getSubTitle(event: any): string {
    return 'Subtitle'
  }

  getHtmlWidthFactor(event: any): number {
    return 1
  }

  public getHTML(d: Vertex) {
    const blogArticle = d.timelineEvent as BlogArticle
    const author = Object.values(this.mycelModel.data.people).find(
      (p) => p.uuid === blogArticle.author.id
    )
    const photoUrl = author!.photo + '/m/filters:grayscale()'

    return `<h1 class='title'>${blogArticle.title}</h1>
        <div class='author'>
          <img class='author-photo' src='${photoUrl}' class='author-image'>
            <div>
             <div class='author'><a href='${blogArticle.author.cached_url}'>${
      author!.name
    }</a>                    
             </div>
             <div class='publication-date'>
                ${i18n.global.t('blog.published-on')} ${Intl.DateTimeFormat(i18n.global.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(blogArticle.date)}
            </div>       
           </div> 
      </div>       
      ${d.timelineEvent?.description ?? ''}`
  }

  render(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean,
  ): void {
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())

    if (!isZooming) {
      this.createGroupSvg(g, vertices)
      this.createZoomedOutTitle()
    }
  }
}
