<script setup lang="ts">
import BubbleOverlayHeader from '@/components/bubble-overlay/BubbleOverlayHeader.vue'
import type {
  BlogOverlayData,
  Offering,
  PersonOverlayData,
  Podcast,
  Project,
  TeamEvent,
  Vertex
} from '../../../types'
import { computed } from 'vue'
import { BUBBLE_TYPES } from '@/constants/constants'
import i18n from '@/i18n'
import ProjectBubbleOverlay from '@/components/bubble-overlay/bubbles/ProjectBubbleOverlay.vue'
import BlogBubbleOverlay from '@/components/bubble-overlay/bubbles/BlogBubbleOverlay.vue'
import ServiceBubbleOverlay from '@/components/bubble-overlay/bubbles/ServiceBubbleOverlay.vue'
import TeamMemberBubbleOverlay from '@/components/bubble-overlay/bubbles/TeamMemberBubbleOverlay.vue'
import PodcastBubbleOverlay from '@/components/bubble-overlay/bubbles/PodcastBubbleOverlay.vue'
import TeamEventBubbleOverlay from '@/components/bubble-overlay/bubbles/TeamEventBubbleOverlay.vue'

const props = defineProps<{ overlayData: Vertex, bubbleSizes: {width: string, height: string } }>()
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'click-all'): void
  (e: 'go-to-prev'): void
  (e: 'go-to-next'): void
}>()

const bubbleTitle = computed(() => {
  switch (props.overlayData.timelineEvent?.class) {
    case BUBBLE_TYPES.PROJECT:
      return i18n.global.t('classTitleLabel.project')
    case BUBBLE_TYPES.BLOG_ARTICLE:
      return i18n.global.t('classTitleLabel.blog')
    case BUBBLE_TYPES.SERVICE:
      return i18n.global.t(
        `classTitleLabel.${(props.overlayData.timelineEvent as Offering).component}`
      )
    case BUBBLE_TYPES.TEAM_MEMBER:
      return i18n.global.t('classTitleLabel.teamMembers')
    case BUBBLE_TYPES.PODCAST:
      return i18n.global.t('classTitleLabel.podcast')
    case BUBBLE_TYPES.TEAM_EVENT:
      return i18n.global.t('classTitleLabel.teamEvent')
    default:
      return ''
  }
})

const hideAllButton = computed(
  () => props.overlayData.timelineEvent?.class === BUBBLE_TYPES.TEAM_EVENT
)

</script>

<template>
  <div class="overlay-mask" @click.stop="emit('close')">
    <div class="overlay" :style="bubbleSizes" aria-modal="true" @click.stop>
      <div class="overlay-event-body">
        <BubbleOverlayHeader
          :title="bubbleTitle"
          :hideAllButton="hideAllButton"
          @close="emit('close')"
          @click-all="emit('click-all')"
          @go-to-next="emit('go-to-next')"
          @go-to-prev="emit('go-to-prev')"
        ></BubbleOverlayHeader>
        <BlogBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.BLOG_ARTICLE"
          :blog-data="overlayData.timelineEvent as BlogOverlayData"
        ></BlogBubbleOverlay>
        <ServiceBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.SERVICE"
          :service-data="overlayData.timelineEvent  as Offering"
        ></ServiceBubbleOverlay>
        <ProjectBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.PROJECT"
          :project-data="overlayData.timelineEvent as Project"
        ></ProjectBubbleOverlay>
        <TeamMemberBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.TEAM_MEMBER"
          :team-member-data="overlayData.timelineEvent as PersonOverlayData"
        ></TeamMemberBubbleOverlay>
        <PodcastBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.PODCAST"
          :podcast-data="overlayData.timelineEvent as Podcast"
        ></PodcastBubbleOverlay>
        <TeamEventBubbleOverlay
          v-if="overlayData.timelineEvent?.class === BUBBLE_TYPES.TEAM_EVENT"
          :team-event-data="overlayData.timelineEvent as TeamEvent"
        ></TeamEventBubbleOverlay>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay-mask {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: auto;
  z-index: 1101;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.overlay {
  background: var(--gold);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.16);
  border: 0 none;
  min-width: 40%;
  min-height: 55%;
  transition: all 0.3s;
  align-self: center;
  display: flex;
  justify-content: center;
  border-radius: 0;
  height: 100%;
  width: 100%;

  :deep(.description) {
    @apply font-normal;
  }

  :deep(.black-button.circle) {
    @apply p-2 w-8 h-8 text-xs;
  }
  :deep(.social-media) {
    @apply gap-2;
    a {
      @apply w-8 h-8 text-xs;
    }
  }
}

.overlay-event-body {
  width: 100%;
  padding: 20px 0 20px 20px;
  height: 100%;
}

@media screen(sm) {
  .overlay {
    .overlay-event-body {
      padding: 40px 0 40px 40px;
    }

    :deep(.black-button.circle) {
      @apply p-3 w-12 h-12 text-lg;
    }

    :deep(.social-media) {
      @apply gap-3;
    }
  }
}

@media screen(lg) {
  .overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    .overlay-event-body {
      padding-top: calc(20vh + 40px);
      height: calc(100% - 20vh);
      width: 65%;

      :deep(.title),
      :deep(.subtitle) {
        font-size: 64px;
        line-height: 64px;
      }
    }

    :deep(.black-button.circle) {
      width: 56px;
      height: 56px;
      @apply p-3 text-xl;
    }

    :deep(.social-media) {
      @apply gap-3;
      a {
        width: 56px;
        height: 56px;
        @apply text-xl;
      }
    }
  }
}

@media screen('2xl') {
  .overlay {
    font-size: 28px;
  }
  .overlay .overlay-event-body {
    font-size: 28px;
  }
}

@media (min-width: 2000px) {
  .overlay {
    font-size: 32px;

    .overlay-event-body {
      font-size: 32px;

      :deep(.title),
      :deep(.subtitle) {
        font-size: 96px;
        line-height: 96px;
      }
    }
  }
}
</style>
