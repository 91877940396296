<script setup lang="ts">
import type { Project } from '../../../../types'

defineProps<{ projectData: Project }>()
const galleryW = 400
</script>

<template>
  <div class="h-full">
    <div class="project-bubble-content overflow-auto pr-5 sm:pr-[30px]">
      <div class="flex gap-4 mb-7">
        <div v-if="projectData?.logo_url" class="project-logo">
          <img
            class="w-auto h-full"
            style="object-fit: contain"
            :src="projectData?.logo_url"
            alt="Logo"
          />
        </div>
        <h1 v-else class="title">{{ projectData?.title }}</h1>
      </div>
      <div
        v-if="projectData?.description"
        class="description mb-10"
        v-html="projectData?.description"
      ></div>
      <div v-if="projectData?.pictures?.length" class="my-10 flex flex-col gap-5">
        <div v-for="image of projectData?.pictures" :key="image.filename" class="images">
          <img :src="image.filename + '/m/' + galleryW * 4 + 'x0'" alt="Project Photo" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.project-logo {
  @apply w-full h-[100px];
}
.project-bubble-content {
  height: calc(100% - 3em);
}

@media screen(sm) {
  .project-bubble-content {
    height: calc(100% - 3.5em); /* header + title */
  }
}

@media screen(lg) {
  .project-bubble-content {
    height: calc(100% - 3em); /* header + title */
  }
}
</style>
