<script setup lang="ts">
import type { PersonOverlayData } from '../../../../types'
import { computed } from 'vue'

const props = defineProps<{ teamMemberData: PersonOverlayData }>()

const person = computed(() => props.teamMemberData?.person_details)
</script>

<template>
  <div class="team-member-bubble-content">
    <h1 class="title mt-0">{{ teamMemberData?.title }}</h1>
    <h2 class="subtitle mb-7 mt-0 pt-0">
      {{ $t(`position.${person?.position ?? ''}`) }}
    </h2>
    <div class="team-member-content">
      <div v-if="person?.description" class="person-description">
        <div v-if="person?.description" class="description mb-5" v-html="person?.description"></div>
        <div class="social-media">
          <a class="black-button circle" v-if="person?.urlLinkedin" :href="person?.urlLinkedin" target="_blank">

            <img src="@/assets/linkedin.svg" alt="linkedin logo" class="w-full h-full" />
          </a>
          <a class="black-button circle" v-if="person?.urlGithub" :href="person?.urlGithub" target="_blank">
            <img src="@/assets/github.svg" alt="github logo" class="w-full h-full" />
          </a>
        </div>
      </div>
      <div class="person-photo">
        <img :src="person?.photo" alt="person photo" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.team-member-bubble-content {
  height: calc(100% - 3em);
  @apply overflow-auto flex flex-col pr-5 sm:pr-[30px];

  .subtitle {
    @apply mb-1;
  }

  .team-member-content {
    @apply flex flex-col w-full;

    .person-description {
      @apply w-full order-2 flex flex-col;
    }

    .person-photo {
      @apply order-1 flex justify-start items-center pb-3 w-full self-center;
      img {
        -o-object-fit: cover;
        object-fit: cover;
        shape-outside: circle();
        border-radius: 50%;
        min-width: 56px;
        min-height: 56px;
        width: 50%;
      }
    }
  }
}

@media screen(sm) {
  .team-member-bubble-content {
    height: calc(100% - 3em);

    .subtitle {
      @apply mb-4;
    }

    .team-member-content {
      @apply flex flex-row;
      gap: 40px;

      .person-description {
        @apply w-1/2 order-1 mr-3;
      }

      .person-photo {
        @apply h-full items-start pt-3 w-1/2 order-2 px-0;

        img {
          @apply w-full;
        }
      }
    }
  }
}
</style>
