import type { Point } from '../../types'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

export abstract class EdgeHighlighterCurves {
  width: number
  height: number
  deviceHandler: DeviceAbstract

  constructor(width: number, height: number, deviceHandler: DeviceAbstract) {
    this.width = width
    this.height = height
    this.deviceHandler = deviceHandler
  }

  public abstract getFirstPoint(): Point

  protected abstract curve(t: number): Point

  protected distance(p1: Point, p2: Point) {
    return (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2
  }

  protected closestDistanceToCurve(p: Point, tMin: number, tMax: number, step = 0.01) {
    let minDist = Infinity
    for (let t = tMin; t <= tMax; t += step) {
      const pt = this.curve(t)
      const dist = this.distance(p, pt)
      minDist = Math.min(minDist, dist)
    }
    return minDist
  }

  public distanceFromCurve(p: Point): number {
    return this.closestDistanceToCurve(p, 0, 1, 0.01)
  }
}

export class SineCurve extends EdgeHighlighterCurves {
  private frequencyCorrection: number = 1.3

  public getFirstPoint(): Point {
    return {
      x: this.width * (1 - this.deviceHandler.getConfig().offsetHorizontalRelative),
      y: (1 - this.deviceHandler.getConfig().offsetVerticalRelative) * this.height
    }
  }

  protected curve(t: number): Point {
    return {
      x:
        ((-Math.sin((1.25 * t - 0.25) * Math.PI * 2 * this.frequencyCorrection) * this.width) / 2) *
          (1 - this.deviceHandler.getConfig().offsetHorizontalRelative) +
        this.width / 2,
      y: (1 - t) * this.height * (1 - this.deviceHandler.getConfig().offsetVerticalRelative)
    }
  }
}

export class CaretCurve extends EdgeHighlighterCurves {
  public getFirstPoint(): Point {
    return { x: 0, y: 0 }
  }

  protected curve(t: number): Point {
    const y = t * this.height
    if (y < this.height / 2) {
      return { x: ((y * this.width) / this.height) * 2, y }
    } else {
      return { x: (((this.height - y) * this.width) / this.height) * 2, y }
    }
  }
}
