import type {
  Project,
  TimelineEvent,
  TimelineEventClasses,
  Vertex
} from '../../../../types'
import * as d3 from 'd3'
import type { BaseType } from 'd3'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'

export default class ProjectRenderer extends AbstractSvgRenderer {
  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 80,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true,
  }

  public getClassName(): TimelineEventClasses {
    return 'project'
  }

  public getClassTitle(d: Project) {
    return 'Project'
  }

  public getSubTitle(d: Project) {
    return ''
  }

  public getHtmlWidthFactor(d: Project) {
    return 1
  }

  public getHTML(d: Vertex) {
    // parent element: div.event-body
    return `${d.timelineEvent?.description ?? ''}
                ${d.timelineEvent ? this.getGalleryHTML(d.timelineEvent) : ''}`
  }

  private getGalleryHTML(event: TimelineEvent) {
    const pictures = event?.pictures || []
    if (!pictures.length) {
      return ''
    }

    const galleryW = 400

    return `${pictures
                      .map(
                        (p: { filename: string; title: string }) => `
                            <img style='margin-top: 10px;'
                              src='${p.filename}/m/${galleryW * 4}x0' 
                              ></img>`
                      )
                      .join('')}`
  }

  public render(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean = false,
    isFiltering: boolean = false,
  ) {

    const vertices = this.mycelModel.getVerticesByClass('project')

    if (!isZooming) {
      this.createGroupSvg(g, vertices)
    }

    if (!isZooming) {
      g
        .selectAll<SVGGElement | BaseType, Vertex>('svg.project')
        .append('g')
        .attr('class', (d) => `project project-logo project-${d.timelineEvent!.id}`)
    }
    if (isFiltering) {
      g.selectAll<SVGGElement | BaseType, Vertex>('g.project-logo').raise()
    }

    const gProject = g.selectAll<SVGGElement | BaseType, Vertex>('g.project-logo').attr('transform', (d: Vertex) => {
      // put logo in the center of the bubble
      return `translate(33,23.4) scale(21)`
    })

    // project logo. Will be created only once. So we don't run this during zooming.
    if (!isZooming) {
      gProject
        .append('image')
        .attr('xlink:href', (d: Vertex) => {
          return (d.timelineEvent as Project).logo_url as string
        })
        .attr('width', 16)
        .attr('height', 16)
        .attr('aria-label', (d: Vertex) => {
          return (d.timelineEvent as Project).title as string
        })
    }
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.project'
  }

  public resetFilter(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    super.resetFilter(g)
    this.render(g, true, false)
  }


  protected updateElementsOnFiltering(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
  ) {
    this.render(g, true, true)
  }
}
