<script setup lang="ts">
import type { TeamEvent } from '../../../../types'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperNavigation from '@/components/swiper/SwiperNavigation.vue'
import { computed } from 'vue'
import 'swiper/css'

const props = defineProps<{ teamEventData: TeamEvent }>()

const photos = computed(() => props.teamEventData.photos || [])
</script>

<template>
  <div class="team-event-bubble-content pr-5 sm:pr-[30px]">
    <h1 class="title mb-7 mt-0">{{ teamEventData?.title }}</h1>
    <div class="team-event-galleria">
      <swiper class="w-full h-full" :loop="true" :css-mode="true" :lazy="true">
        <SwiperNavigation />
        <swiper-slide class="w-full h-full" v-for="img of photos" :key="img.filename">
          <img :src="img.filename" alt="Team event photo" class="team-event-photo" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style scoped>
.team-event-bubble-content {
  height: calc(100% - 4.5em);
  overflow: hidden;

  .team-event-galleria {
    height: calc(100% - 5em);
    @apply w-full relative;

    .team-event-photo {
      -o-object-fit: cover;
      object-fit: cover;
      @apply w-full h-full;
    }

    :deep(.arrows) {
      opacity: 0;
    }
  }

  .team-event-galleria:hover {
    :deep(.arrows) {
      opacity: 1;
    }
  }
}
</style>
