import type { Offering, TimelineEventClasses, Vertex } from '../../../../types'
import type { Selection } from 'd3'
import {
  AbstractSvgRenderer,
  type GatheredBubble
} from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import * as d3 from 'd3'

export default class OfferingRenderer extends AbstractSvgRenderer {
  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 80,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true,
  }

  getClassName(): TimelineEventClasses {
    return 'offering'
  }

  getCssClassName(d: Vertex) {
    const Offering = d.timelineEvent as Offering
    return `offering offering-component-${Offering.component} ${Offering.isTitle ? 'offering-title' : '' }`
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.' + ((d.timelineEvent as Offering).component ?? '')
  }

  public getHTML(d: Vertex) {
    return `<h1 class='title'>${d.timelineEvent?.title}</h1>` + d.timelineEvent?.description ?? ''
  }

  getHtmlWidthFactor(event: any): number {
    return 1
  }

  getSubTitle(event: any): string {
    return 'Subtitle'
  }

  render(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean,
  ): void {
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())

    if (!isZooming) {
      this.createGroupSvg(g, vertices)
      this.createZoomedOutTitle()
    }
  }

  private rotatePoint(
    point: { x: number; y: number },
    angleDegrees: number,
    center: { x: number; y: number }
  ): { x: number; y: number } {
    const radians = (angleDegrees * Math.PI) / 180
    const cosTheta = Math.cos(radians)
    const sinTheta = Math.sin(radians)

    // Translate point to origin
    const translatedX = point.x - center.x
    const translatedY = point.y - center.y

    // Rotate point
    const rotatedX = translatedX * cosTheta - translatedY * sinTheta
    const rotatedY = translatedX * sinTheta + translatedY * cosTheta

    // Translate point back
    const finalX = rotatedX + center.x
    const finalY = rotatedY + center.y

    return { x: finalX, y: finalY }
  }

  public async filter(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    width: number,
    height: number,
    onCompletionFn: () => void
  ) {
    // This version of the Filter is using a static positioning.
    // The output should be a GatheredPoint[] that can be used in gatherElements()
    // see https://drive.google.com/file/d/1_cvEVHHbNLvAnvpXlxcYOVnaxCatMsbS/view?usp=sharing
    const positions = [
      [null, 371862601, 371862578, null, null],
      [371859820, 418882710, 371859814, 371862820, null],
      [371856231, 371856230, 371859799, 371862815, 371862814],
      [418873782, 371856229, 371862809, 418882687, null],
      [null, null, null, 371862657, null]
    ]

    const targetRadius =
      this.config.filterCircleRadius * this.deviceHandler.getConfig().filteredCircleScaleDownFactor

    const vertices = this.mycelModel.getVerticesByClass('offering')

    const nodeData: GatheredBubble[] = []

    const x0 = (width - positions[0].length * targetRadius * 2) / 2 + targetRadius * 2
    const y0 = (height - positions.length * targetRadius * Math.sqrt(2)) / 2

    for (let y = 0, yl = positions.length; y < yl; y++) {
      for (let x = 0, xl = positions[y].length; x < xl; x++) {
        const vertex = vertices.find((v) => v.timelineEvent?.id == positions[y][x]?.toString())
        if (vertex?.timelineEvent?.id) {
          // These calculation place the bubbles in a way that they appear glued to each other.
          // It's a hexagonal grid.
          const orig = {
            x: x0 + x * targetRadius * 2 - ((y + 1) % 2) * targetRadius,
            y: y0 + y * targetRadius * Math.sqrt(3)
          }

          const rotated = this.rotatePoint(orig, 0, { x: width / 2, y: height / 2 })
          nodeData.push({
            id: vertex.timelineEvent.id,
            x: rotated.x,
            y: rotated.y,
            radiusScale: 1
          })
        }
      }
    }
    onCompletionFn()
    // We delay this so that the events are in front of the overlay
    window.setTimeout(() => {
      this.gatherElements(g, nodeData, 1, targetRadius, vertices)
    }, 500)
  }

  public resetFilter(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    super.resetFilter(g)
    this.render(g, true)
  }
}
