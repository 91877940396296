/*
Stores data about the Mycel, provides functionality to manipulate it
 */

import type {
  Edge,
  Vertex,
  TimelineEvent,
  VertexType,
  EdgeType,
  FreefloatContent,
  MycelData,
  TimelineEventClasses,
  SerializableModel,
  PersonOnboarding, Offering,
} from '../../types'
import { Delaunay } from 'd3-delaunay'
import GraphTools from '@/lib/GraphTools'
import { range } from 'd3'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

interface EdgeGrowHistoryItem {
  timelineEvent: TimelineEvent
  edgePath: Edge[]
}

export class MycelModel {
  public data: MycelData
  public edges: Edge[]
  public vertices: Record<string, Vertex>
  public delaunay: Delaunay<Delaunay.Point> | null
  public timelineVertices: Vertex[] = [] // Vertices on timeline (only the thick line in the middle)
  public welcomeVertex: Vertex | undefined = undefined // The vertex which contains the welcome message
  public edgeGrowHistory: EdgeGrowHistoryItem[] = []

  constructor(data: MycelData) {
    this.data = data
    this.edges = []
    this.vertices = {}
    this.delaunay = null
  }

  /**
   * Uses the Delaunay triangulation to calculate the skeleton of the Mycel
   * @param points
   *
   */
  public generateEdges(points: Vertex[], edgeRetentionRate: number = 1) {
    this.delaunay = Delaunay.from(points.map((p) => [p.x, p.y]))
    const edgeSet = new Set<string>()

    for (let i = 0; i < this.delaunay.triangles.length; i += 3) {
      for (let j = 0; j < 3; j++) {
        let p1 = points[this.delaunay.triangles[i + j]]
        let p2 = points[this.delaunay.triangles[i + ((j + 1) % 3)]]

        // Sort p1 and p2 so that the smallest is first
        if (p2.x < p1.x || (p2.x === p1.x && p2.y < p1.y)) {
          ;[p1, p2] = [p2, p1]
        }

        const p1rec = this.getOrCreate(p1)
        const p2rec = this.getOrCreate(p2)

        // Create edge string and check if it's in the set
        const edgeStr = `${p1.x},${p1.y}-${p2.x},${p2.y}`
        if (!edgeSet.has(edgeStr) && GraphTools.random() < edgeRetentionRate) {
          edgeSet.add(edgeStr)
          this.edges.push({ source: p1rec, target: p2rec, type: 'neutral' })
        }
      }
    }
    //this.addIdsToVertices()
  }

  public markAsTimeline(
    p1: Vertex,
    p2: Vertex,
    edgeType: EdgeType,
    vertexType?: VertexType
  ): Edge | undefined {
    // Sort p1 and p2 so that the smallest is first
    if (p1.x < p2.x || (p1.x === p2.x && p1.y < p2.y)) {
      ;[p1, p2] = [p2, p1]
    }
    let addedEdge

    this.edges
      .filter((edge) => {
        return (
          edge.source.x === p2.x &&
          edge.source.y === p2.y &&
          edge.target.y === p1.y &&
          edge.target.x === p1.x
        )
      })
      .forEach((edge) => {
        edge.type = edgeType
        const addVertexToTimeline = (VertexToAdd: Vertex) => {
          if (vertexType) {
            VertexToAdd.type = vertexType
          }
          const exists = this.timelineVertices.some(
            (item) => JSON.stringify(item) === JSON.stringify(VertexToAdd)
          )
          if (!exists && vertexType == 'timeline-connector') {
            this.timelineVertices.push(VertexToAdd)
          }
        }
        addVertexToTimeline(edge.source)
        addVertexToTimeline(edge.target)
        addedEdge = edge
      })
    return addedEdge
  }

  public assignYearsLabels(minDistanceFromOtherVertices: number, deviceHandler: DeviceAbstract) {
    // we want a formula where now returns 1 and the start year returns 0
    const now = new Date()
    // Get the first of january of the year of the first event in the timeline
    const lowestDate = new Date(Object.keys(this.data.timeline).sort()[0])

    const easeOutSemiQuad = (x: number) => (Math.pow(x, deviceHandler.getConfig().timelineYearsEasingFactor) + x) / 2
    const dateInterpolator = (date: Date) => {
      const diff = date.getTime() - lowestDate.getTime()
      const diffNow = now.getTime() - lowestDate.getTime()
      return easeOutSemiQuad(diff / diffNow) // CoPilot reversed the two values here
    }

    // Make sure that we insert projects first.
    const orderToInsert: TimelineEventClasses[] = ['podcast', 'blogarticle', 'offering', 'project', 'team-event', 'person-onboarding']
    for (const cls of orderToInsert) {
      const eventsByClass = Object.values(this.data.timeline).filter((e) => e.class === cls)
      eventsByClass.forEach((event: TimelineEvent) => {
        this.assignEventToTimeline(dateInterpolator, event, minDistanceFromOtherVertices)
      })
    }

    this.assignYearLabelsToTimeline(dateInterpolator, lowestDate, now)
  }

  private assignEventToTimeline(
    dateInterpolator: (date: Date) => number,
    currentEvent: TimelineEvent,
    minDistanceFromOtherVertices: number = 0
  ) {
    if (currentEvent && currentEvent.date) {
      const indexToAssign = Math.min(Math.floor(
        dateInterpolator(currentEvent.date) * this.timelineVertices.length
      ), this.timelineVertices.length - 1)
      const bud = this.timelineVertices[indexToAssign]

      const edgeGrowHistoryItem: EdgeGrowHistoryItem = {
        timelineEvent: currentEvent,
        edgePath: []
      }

      // We do this here to make sure that we're not using an edge used by another Event
      let candidateEdge = GraphTools.findConnectedEdges(
        this.edges,
        bud,
        false,
        [],
        ['neutral'],
        ['node']
      )
      if (candidateEdge.length === 0) {
        // In this case we are trying to reuse already used edges
        candidateEdge = GraphTools.findConnectedEdges(
          this.edges,
          bud,
          false,
          [],
          ['neutral', 'timeline', 'timeline-event'],
          ['node', 'event', 'event-connector']
        )
      }
      if (candidateEdge.length === 0) {
        console.warn('No more edges to assign events to', currentEvent, bud.id)
      } else {
        //const optimalEdge = this.rateConnectedEdgeByLeastAttractedPoint(bud, candidateEdge)[0].edge
        const optimalEdge = candidateEdge[Math.floor(Math.random() * candidateEdge.length) | 0]
        edgeGrowHistoryItem.edgePath = [optimalEdge]
        const edgePath = this.growBranch(
          currentEvent,
          bud,
          optimalEdge,
          minDistanceFromOtherVertices
        )
        edgeGrowHistoryItem.edgePath.push(...edgePath)
        this.edgeGrowHistory.push(edgeGrowHistoryItem)
      }
    }
  }

  private assignYearLabelsToTimeline(
    dateInterpolator: (date: Date) => number,
    lowestDate: Date,
    now: Date
  ) {
    const yearDates = range(lowestDate.getFullYear(), now.getFullYear() + 1).map(
      (year) => new Date(year, 0, 1)
    )
    yearDates[0] = lowestDate

    this.timelineVertices.forEach((p, ix) => {
      p.type = 'timeline-connector'
    })

    let ix = 0
    yearDates.forEach((date) => {
      ix = Math.floor(dateInterpolator(date) * this.timelineVertices.length)
      if (ix < this.timelineVertices.length) {
        this.timelineVertices[ix].type = 'timeline-year'
        this.timelineVertices[ix].year = date.getFullYear()
      }
    })

    if (ix < this.timelineVertices.length - 1) {
      this.timelineVertices[this.timelineVertices.length - 1].type = 'timeline-year'
      this.timelineVertices[this.timelineVertices.length - 1].year = 0
    }
  }

  /**
   * Returns the data necessary for the prerendering. That's the vertex and edge data, but without the timeline events
   * applied. The coordinates are normalized in a range 0..1.
   */
  public getPrerenderingData(width: number, height: number) {
    type PrerenderingVertex = Vertex & { tmpkey: string }
    type PrerenderingVertices = Record<string, PrerenderingVertex>
    // Give each vertex a unique key and store it in the vertex
    Object.values(this.vertices).forEach((vertex) => {
      const v = vertex as PrerenderingVertex
      v.tmpkey = `${v.x.toFixed(0)},${v.y.toFixed(0)}`
    })

    const data: SerializableModel = {
      vertices: this.vertices,
      edges: this.edges,
      timelineVertexKeys: this.timelineVertices.map((p) => {
        return (p as PrerenderingVertex).tmpkey
      }),
      welcomeVertexKey: `${(this.welcomeVertex as PrerenderingVertex).tmpkey}`
    }

    // scale x and y coordinates of all vertices
    const clone = structuredClone(data)

    // normalize the coordinates from 0 to 1
    for (const key in clone.vertices) {
      const v = clone.vertices[key]
      if (v.x && v.y) {
        v.xStandard = v.x / width
        v.yStandard = v.y / height
      }
    }

    // Replace key of this.vertices with it's value from vertice.tmpkey
    const vertices = Object.values(clone.vertices as PrerenderingVertices)
    const newKeyedVertices = vertices.reduce((acc, vertex) => {
      acc[vertex.tmpkey] = vertex
      return acc
    }, {} as PrerenderingVertices)
    clone.vertices = newKeyedVertices

    return JSON.stringify(clone, (key, value) => {
      // vertices
      if (key === 'type' && value === 'event') {
        return 'node'
      }

      // edges
      if (key === 'type' && value === 'timeline-event') {
        return 'neutral'
      }

      if (key === 'source' || key === 'target') {
        return (value as PrerenderingVertex).tmpkey
      }

      if (key === 'x' || key === 'y' || key === 'tmpkey') {
        return undefined
      }

      if (key === 'timelineEvent') {
        return undefined
      }

      // Years will be assigned at runtime
      if (key === 'year') {
        return undefined
      }

      if (value === 'timeline-year') {
        return 'timeline-connector'
      }

      return value
    })
  }

  /**
   * Restores the data from the prerendering. The coordinates are scaled to the current window size.
   * @param data
   * @param width
   * @param height
   */
  public restorePrerenderingData(data: SerializableModel, width: number, height: number) {
    this.vertices = data.vertices as Record<string, Vertex>

    this.edges = data.edges.map((edge: any) => {
      const sourceKey = edge.source
      const targetKey = edge.target
      edge.source = this.vertices[sourceKey]
      edge.target = this.vertices[targetKey]
      return edge
    })

    // replace the timelineVertices with the actual vertices
    this.timelineVertices = data.timelineVertexKeys.map((k) => {
      return this.vertices[k]
    })

    // scale x and y coordinates of all vertices to the current window size
    for (const key in this.vertices) {
      const v = this.vertices[key]
      v.id = key
      v.x = v.xStandard! * width
      v.y = v.yStandard! * height
      delete v.xStandard
      delete v.yStandard
    }

    this.welcomeVertex = this.vertices[data.welcomeVertexKey]
  }

  public setWelcomeVertex(vertex: Vertex) {
    this.welcomeVertex = vertex
    vertex.timelineEvent = {
      id: 'welcome',
      title: '',
      description: '',
      class: 'freefloat'
    }
  }

  public getWelcomeVertex() {
    return this.welcomeVertex
  }

  protected getYearVertices() {
    return this.timelineVertices.filter((p) => {
      return p.year !== undefined
    })
  }

  /**
   * Find all vertices from p. Used for avoiding collisions
   * @param p
   * @param VerticesToIgnore
   * @param distance Either a number or a map of VertexType to number
   * @param ignoreType
   */
  protected findVerticesInDistance(
    p: Vertex,
    VerticesToIgnore: Vertex[],
    distance: number | { [K in VertexType]: number },
    ignoreType: VertexType
  ) {
    return Object.values(this.vertices)
      .filter((vertex) => {
        return VerticesToIgnore.every((VertexToIgnore) => {
          return vertex.x !== VertexToIgnore.x && vertex.y !== VertexToIgnore.y
        })
      })
      .filter((vertex) => {
        // TODO: this is not a real distance calculation
        let dVal: number
        if (typeof distance === 'object') {
          dVal = distance[vertex.type]
        } else {
          dVal = distance
        }
        return (
          Math.abs(vertex.x - p.x) < dVal &&
          Math.abs(vertex.y - p.y) < dVal &&
          vertex.type !== ignoreType &&
          (vertex.x !== p.x || vertex.y !== p.y)
        )
      })
  }

  /**
   * Returns the next or previous event on the timeline. The event will be the same class as the current event.
   * @param vertex
   * @param direction
   */
  public getAdjacentTimelineEvent(vertex: Vertex, direction: 'back' | 'forward'): Vertex {
    if (vertex.timelineEvent === undefined) {
      throw new Error('Vertex has no timelineEvent')
    }
    let verticeList = this.getVerticesByClass(vertex.timelineEvent.class)

    // Make sure that if you are looking at a management member, you can navigate through those
    if (vertex.timelineEvent.class == 'person-onboarding') {
      verticeList = verticeList.filter((v) => {
        const currentPerson = this.data.people[(vertex.timelineEvent as PersonOnboarding).person]
        const otherPerson = this.data.people[(v.timelineEvent as PersonOnboarding).person]
        return currentPerson.isManagement == otherPerson.isManagement
      })
    }

    verticeList = verticeList.sort((a, b) => {
      if (a.timelineEvent!.date === undefined || b.timelineEvent!.date === undefined) {
        return 0
      }
      return a.timelineEvent!.date > b.timelineEvent!.date ? 1 : -1
    })
    const currentIndex = verticeList.findIndex(
      (v) => v.timelineEvent!.id === vertex.timelineEvent!.id
    )

    const result =
      direction === 'back'
        ? verticeList[currentIndex === 0 ? verticeList.length - 1 : currentIndex - 1]
        : verticeList[currentIndex === verticeList.length - 1 ? 0 : currentIndex + 1]
    return result
  }

  public getAdjacentFreefloatEvent(vertex: Vertex, direction: 'back' | 'forward'): Vertex {
    if (vertex.timelineEvent === undefined) {
      throw new Error('Vertex has no timelineEvent')
    }
    const verticeList = this.getVerticesByClass(vertex.timelineEvent.class)
      .filter(
        (v) =>
          !(v.timelineEvent as Offering).isTitle &&
          (vertex.timelineEvent as FreefloatContent).storyblokParentId ===
            (v.timelineEvent as FreefloatContent).storyblokParentId
      )
      .sort((a, b) => {
        const a_ = a.timelineEvent as FreefloatContent
        const b_ = b.timelineEvent as FreefloatContent

        if (a_.component === undefined || b_.component === undefined) {
          return 0
        }
        return a_.component > b_.component ? 1 : -1
      })
    const currentIndex = verticeList.findIndex(
      (v) => v.timelineEvent!.id === vertex.timelineEvent!.id
    )

    const result =
      direction === 'back'
        ? verticeList[currentIndex === 0 ? verticeList.length - 1 : currentIndex - 1]
        : verticeList[currentIndex === verticeList.length - 1 ? 0 : currentIndex + 1]
    return result
  }

  /**
   * Returns all vertices that have a timelineEvent of the given class
   * @param cls
   */
  public getVerticesByClass(cls: TimelineEventClasses[]): Vertex[]
  public getVerticesByClass(cls: TimelineEventClasses): Vertex[]
  public getVerticesByClass(cls: TimelineEventClasses | TimelineEventClasses[]): Vertex[] {
    return Object.values(this.vertices).filter((v) => {
      if (Array.isArray(cls)) {
        return v.timelineEvent && cls.includes(v.timelineEvent.class)
      } else {
        return v.timelineEvent && v.timelineEvent.class === cls
      }
    })
  }

  public getVerticesByType(tpe: VertexType | VertexType[]): Vertex[] {
    return Object.values(this.vertices).filter((v) => {
      if (Array.isArray(tpe)) {
        return v.type && tpe.includes(v.type)
      } else {
        return v.type && v.type === tpe
      }
    })
  }

  public getEdgesByType(tpe: EdgeType | EdgeType[]): Edge[] {
    return this.edges.filter((e) => {
      if (Array.isArray(tpe)) {
        return e.type && tpe.includes(e.type)
      } else {
        return e.type && e.type === tpe
      }
    })
  }

  /**
   * Returns vertices by filtering by their slug
   * @param slug
   */
  public getVertexBySlug(slug: string): Vertex {
    return Object.values(this.vertices).find((v) => {
      return v.timelineEvent && v.timelineEvent.slug === slug
    })!
  }

  public getEventById(id: string): TimelineEvent {
    return Object.values(this.data.timeline).find((v) => {
      return v.id == id
    })!
  }

  /**
   * Used to create the connections between events and the timeline. Used by assignTimelineEvents().
   * The difference between this function and assignTimelineEvents() is that this function
   * does not do the connection to the Timeline. Just the connection to the root edge of timeline branches.
   * @param event
   * @param vertexOnTimeline The vertex that's sitting on the timeline.
   * @param edge The edge that connects the year vertex to the next vertex
   * @param minDistanceFromOtherVertices
   */
  protected growBranch(
    event: TimelineEvent,
    vertexOnTimeline: Vertex,
    edge: Edge,
    minDistanceFromOtherVertices: number
  ): Edge[] {
    let currentVertex = vertexOnTimeline
    if (event) {
      const currentPath: Vertex[] = []
      edge.type = 'timeline-event'
      let otherEndpoint = GraphTools.getOtherEndpoint(edge, currentVertex)
      const distanceMap: { [K in VertexType]: number } = {
        node: 0,
        'timeline-connector': 10,
        'timeline-year': 40,
        'event-connector': 10,
        event: 20,
        welcomevertex: 20
      }
      let closestVertices = this.findVerticesInDistance(
        otherEndpoint,
        currentPath,
        distanceMap,
        'node'
      )
      let connectedEdges = GraphTools.findConnectedEdges(
        this.edges,
        otherEndpoint,
        false,
        [],
        ['neutral', 'timeline', 'timeline-event'],
        ['node', 'event', 'event-connector']
      )

      const visitedEdges = []

      // follow the edges until we find a node that is not too close to other nodes
      while (otherEndpoint.type === 'event' && connectedEdges.length > 0) {
        // this is a branch going through an existing branch with an event
        if (otherEndpoint.type !== 'event') {
          otherEndpoint.type = 'event-connector'
        }
        // highlight the current edge
        this.markAsTimeline(currentVertex, otherEndpoint, 'timeline-event')
        currentVertex = otherEndpoint
        currentPath.push(currentVertex)

        const optimalEdge = connectedEdges[Math.floor(Math.random() * connectedEdges.length) | 0]

        // we store the visited edges to avoid loops
        visitedEdges.push(optimalEdge)
        otherEndpoint = GraphTools.getOtherEndpoint(optimalEdge, otherEndpoint)
        closestVertices = this.findVerticesInDistance(
          otherEndpoint,
          currentPath,
          distanceMap,
          'node'
        )
        connectedEdges = GraphTools.findConnectedEdges(
          this.edges,
          otherEndpoint,
          false,
          visitedEdges,
          ['neutral', 'timeline', 'timeline-event'],
          ['node', 'event', 'event-connector']
        )

        if (connectedEdges.length === 0) {
          console.warn('No more edges to assign events to', event, otherEndpoint.id, visitedEdges)
        }
      }
      // assigne the timelineevent to the last vertex
      if (otherEndpoint.type == 'event') {
        console.warn(
          `Event ${event.title} already assigned to ${otherEndpoint.timelineEvent?.title}`
        )
      }

      otherEndpoint.timelineEvent = event
      otherEndpoint.type = 'event'
      this.markAsTimeline(currentVertex, otherEndpoint, 'timeline-event')
      return visitedEdges
    }
    return []
  }

  /**
   * Creates a new point if it doesn't exist yet, otherwise returns the existing point
   * @param p
   * @returns {Vertex}
   * @protected
   */
  protected getOrCreate(p: Vertex): any {
    const key = `${p.x},${p.y}`
    // avoid "Do not access Object.prototype method 'hasOwnProperty' from target object."
    if (!Object.prototype.hasOwnProperty.call(this.vertices, key)) {
      this.vertices[key] = p
    }
    return this.vertices[key]
  }
}
