<script setup lang="ts">
import NavigationMain from '@/components/NavigationMain.vue'
import Mycelium from '@/components/Mycelium.vue'

import { computed, ref } from 'vue'
import { useMenuStore } from '@/store'
import FooterLine from '@/components/FooterLine.vue'
import { useRouter } from 'vue-router'
import { isSafari, isWindows } from '@/lib/Utils/Utils'

const menuStore = useMenuStore()

const isMobile = window.innerWidth <= 768
// For small macbook screens where the width is much larger than the height
const isCinematic = window.innerWidth > 768 && window.innerWidth / window.innerHeight > 1.8

const myzelium = ref<typeof Mycelium | null>(null)

menuStore.isMobile = isMobile

// Test if route is /privacy
const isPrivacyRoute = computed(() => {
  const router = useRouter()
  return router.currentRoute.value.path === '/privacy' || router.currentRoute.value.path === '/chat'
})

console.log(import.meta.env.VITE_BACKEND_URL)
</script>
<template>
  <header>
    <NavigationMain></NavigationMain>
  </header>

  <main :class="{ 'is-mobile': isMobile, 'is-cinematic': isCinematic, 'is-safari': isSafari(), 'is-windows': isWindows(), 'menu-open': menuStore.menuOpen }">
    <router-view ref="myzelium" :mobile="isMobile" :cinematic='isCinematic'></router-view>
  </main>
  <footer v-if='!isPrivacyRoute'>
    <FooterLine ></FooterLine>
  </footer>
</template>

<style>
body {
  padding: 0;
  margin: 0;
}

footer {
  @apply py-4 sm:py-5 sm:px-10 px-4 fixed bottom-0;
}

header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 88px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  @apply py-5 px-5 sm:px-10;
}
</style>
