<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import LangSwitcher from '@/components/LangSwitcher.vue'
import { useMenuStore } from '@/store'
import { storeToRefs } from 'pinia'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/firebase/init'

const router = useRouter()
const route = useRoute()
const menuStore = useMenuStore()

const { menuOpen, requestZoomLevelReset } = storeToRefs(menuStore)

const setMenuOpen = (open: boolean) => {
  menuOpen.value = open
}

// Reset zoom level when navigating to a new page or when clicking the same
// route link twice. The target of this value change is the Mycelium component.
const handleRouterLinkClick = (path: string) => {
  if (router.currentRoute.value.path !== path) {
    router.push(path)
  } else {
    requestZoomLevelReset.value = true
    // Additional logic for when the route is already the target path
  }
}

const goToContactForm = () => {
  router.push('/contact')
}

const goToChat = () => {
  router.push('/chat')
}

const links = [
  {
    path: '/services',
    label: 'navigation.services'
  },
  {
    path: '/projects',
    label: 'navigation.projects'
  },
  {
    path: '/podcast',
    label: 'navigation.podcast',
  },
  {
    path: '/blog',
    label: 'navigation.blog'
  },
  {
    path: '/team',
    label: 'navigation.team'
  }
]

const logClick = (linkId: string) => {
  logEvent(analytics, 'navigation_click', {
    link_id: linkId
  })
}
</script>
<template>
  <div>
    <router-link to="/" @click="
      $emit('resetzoom');
    logClick('logo')
      ">
      <img src="@/assets/logo.svg" alt="logo" />
    </router-link>
  </div>

  <ul class="navigation-lg">
    <li class="item" :class="{ active: route.params.page === 'all' }" @click="logClick('all')">
      <router-link to="/all">{{ $t('navigation.all') }}</router-link>
    </li>
    <li class="separator"></li>
    <li v-for="link in links" v-bind:key="link.label" class="item"
      :class="{ active: '/' + route.params.page == link.path }" @click="logClick(link.path)">
      <a :href="link.path" @click.prevent="handleRouterLinkClick(link.path)">{{ $t(link.label) }}
      </a>
    </li>
    <li class="separator"></li>
    <li class="item">
      <lang-switcher class="circle-icon"></lang-switcher>
    </li>
    <li class="item">
      <button class="black-button" @click="
        goToContactForm();
      logClick('contact')
        ">
        {{ $t('navigation.get_in_touch') }}
      </button>
    </li>
  </ul>
  <ul class="navigation-sm">
    <li>
      <button class="black-button circle" @click="setMenuOpen(!menuOpen)">
        <img v-if="!menuOpen" src="@/assets/menu-icon.svg" alt="open menu"/>
        <img v-else src="@/assets/crossmark.svg" alt="close menu"/>
      </button>
    </li>
    <Transition name="fade">
      <ul v-if="menuOpen" class="left-sidebar">
        <li class="item" :class="{ active: route.params.page === 'all' }">
          <router-link to="/all">{{ $t('navigation.all') }}</router-link>
        </li>
        <li class="separator"></li>
        <li v-for="link in links" v-bind:key="link.label" class="item"
          :class="{ active: '/' + route.params.page == link.path }" @click="logClick(link.path)">
          <a :href="link.path" @click.prevent="handleRouterLinkClick(link.path)">{{ $t(link.label) }}
          </a>
        </li>
        <li class="flex items-center flex-wrap">
          <button class="chat-button-sm mr-2" @click="goToChat(); logClick('chat')">
            {{ $t('navigation.chat_with_us') }}
          </button>
          <button class="black-button black-button-sm" @click="goToContactForm(); logClick('contact')">
            {{ $t('navigation.get_in_touch') }}
          </button>
        </li>
        <li>
          <lang-switcher class="circle-icon"></lang-switcher>
        </li>
      </ul>
    </Transition>
  </ul>
</template>
<style scoped>
.circle-icon {
  height: 60px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #787878;
}

li.active,
li:hover {
  cursor: pointer;
  font-weight: bold;
  color: black;
}

.circle-icon:hover {
  filter: brightness(80%);
}

.navigation-sm,
.navigation-lg {
  @apply flex items-center;
}

.navigation-lg {
  display: none;

  li.item {
    @apply ml-6;
  }

  li.separator {
    border-right: 1px solid #d9d9d9;
    @apply ml-6 h-5;
  }
}

.navigation-sm {
  display: flex;

  >.item {
    padding: 0 6px;
  }

  .circle-icon {
    height: 44px;
  }

  @screen sm {
    .circle-icon {
      height: 60px;
    }
  }
}

@screen xl {
  .navigation-lg {
    display: flex;
  }

  .navigation-sm {
    display: none;
  }
}

.left-sidebar {
  @apply p-5 sm:pl-10 absolute bg-white border-t border-gray-200;
  top: 88px;
  left: 0;
  width: 100%;

  .item {
    @apply mb-5;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.chat-button-sm {
  color: black;
  @apply font-bold px-5 py-3 rounded-full w-64 my-2;
  background-color: var(--gold);
}

.chat-button-sm:hover {
  background-color: var(--middlegold);
}
</style>
