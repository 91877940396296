<template>
  <button class="black-button circle arrows left-arrow" @click="swiper.slidePrev()">
    <img src="@/assets/arrow-left.svg" alt="arrow left" />
  </button>
  <button class="black-button circle arrows right-arrow" @click="swiper.slideNext()">
    <img src="@/assets/arrow-right.svg" alt="arrow right" />
  </button>
</template>
<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()
</script>

<style scoped>
.arrows {
  position: absolute;
  top: 50%;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
</style>
