import type { Podcast, TimelineEventClasses, Vertex } from '../../../../types'
import type { BaseType, Selection } from 'd3'
import * as d3 from 'd3';
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'

export class PodcastRenderer extends AbstractSvgRenderer {
  private holderG: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined> | undefined

  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 140,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true
  }

  getClassName(): TimelineEventClasses {
    return 'podcast'
  }

  getCssClassName(d: Vertex) {
    return 'podcast'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.podcast'
  }

  getSubTitle(event: any): string {
    return 'Subtitle'
  }

  getHtmlWidthFactor(event: any): number {
    return 1
  }

  public getHTML(d: Vertex) {
    const podcast = d.timelineEvent as Podcast

    return `<h1 class='title'>${podcast.title}</h1>`
  }

  private getPodcastImageUrl(podcast: Podcast) {
    if (podcast.published) {
      return podcast.image?.filename + '/m/500x500/smart' ?? ''
    } else {
      return podcast.image?.filename + '/m/500x500/smart/filters:grayscale()' ?? ''
    }
  }

  protected createPhotoSvg(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
  ) {
    this.holderG = g
      .selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
      .append('g')
      .attr('class', (vertex: Vertex) => {
        const p = vertex.timelineEvent as Podcast
        if (p.published) {
          return 'podcast-photo published'
        } else {
          return 'podcast-photo unpublished'
        }
      })
      .attr('clip-path', 'url(#podcastClip)')
      .on('click', (evnet: any, d) => {
        this.dispatchEvent(new CustomEvent('circleClick', { detail: { destination: d } }))
      })

    this.holderG
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.SVGWIDTH)
      .attr('height', this.SVGWIDTH)
      .attr('href', (vertex: Vertex) => {
        const p = vertex.timelineEvent as Podcast
        return this.getPodcastImageUrl(p)
      })
      .attr('clip-path', 'url(#teamEventClip)')
  }

  protected createCropMask(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    this.circleRadii['event']
    g.append('defs')
      .append('clipPath')
      .attr('id', 'podcastClip')
      .append('circle')
      .attr('cx', this.SVGWIDTH / 2)
      .attr('cy', this.SVGWIDTH / 2)
      .attr('r', this.SVGWIDTH / 2)
  }

  protected createTextSvg(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    const podcastText = g.selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
      .append('g')
      .attr('class', 'podcast-text')
      
    podcastText 
      .append('text')
      .attr('class', (d: Vertex) => {
        return 'podcast-title' + ((d.timelineEvent as Podcast).published ? ' published' : ' unpublished')
      })
      .attr('x', 70)
      .attr('y', 299)
      .attr('text-anchor', 'left')
      .text((d: Vertex) => {
        return (d.timelineEvent as Podcast).title ?? ''
      })

    const that = this

    podcastText
      .append('text')
      .attr('class', 'podcast-subtitle')
      .attr('x', 70)
      .attr('y', 254)
      .attr('text-anchor', 'left')
      .each(function (d: Vertex) {
        // console.log((d.timelineEvent as Podcast).people)
        const text = that.textWrap((d.timelineEvent as Podcast).people ?? 'unknown', this, 72, 250)
        // align to bottom
        const bbox = text!.node()?.getBBox()
        const height = bbox?.height ?? 0
        text!.attr('transform', `translate(0, ${(38 - height)})`)
      })
  }

  render(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean,
  ): void {
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())

    if (!isZooming) {
      this.createCropMask(g)
      this.createGroupSvg(g, vertices)
      this.createPhotoSvg(g)
      this.createTextSvg(g)
    }
  }
}
